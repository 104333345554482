import { Injectable, NgZone } from '@angular/core';
import * as auth from 'firebase/auth';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getAuth, updateProfile } from "firebase/auth";
import {AngularFirestore, AngularFirestoreDocument,} from '@angular/fire/compat/firestore';
import { User } from '../shared/user.model';
import { Observable } from 'rxjs';
import { Uzer } from '../shared/user';
import { ToastController } from '@ionic/angular';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  uzer$: Observable<Uzer>;
  userData: any;
  userProfile:any;
  collectionName = 'users';
  constructor(
    public afStore: AngularFirestore,
    public ngFireAuth: AngularFireAuth,
    public router: Router,
    public ngZone: NgZone,
    protected toastCtrl: ToastController
  ) {
    this.ngFireAuth.authState.subscribe((user) => {
      
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));

        this.afStore.doc(`users/${this.userData.uid}`).get().subscribe(res=>{
          if(res.data()){
            this.userProfile = res.data();
            localStorage.setItem('userProfile', JSON.stringify(this.userProfile));
            JSON.parse(localStorage.getItem('userProfile'));
          }else{
            localStorage.setItem('userProfile', null);
            JSON.parse(localStorage.getItem('userProfile'));
            
          }
        });

      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));

        localStorage.setItem('userProfile', null);
        JSON.parse(localStorage.getItem('userProfile'));

        
      }
    });

  }
  // Login in with email/password
  SignIn(email, password) {
    return this.ngFireAuth.signInWithEmailAndPassword(email, password);
  }
  // Register user with email/password
 
  RegisterUser(email, password, name,photoURL, phoneNumber) {
    return this.ngFireAuth.createUserWithEmailAndPassword(email, password).then(data=>{
      this.afStore.collection('users').doc(data.user.uid)
        .set({
          user_uid: data.user.uid,
          email: data.user.email,
          displayName: name,
          photoURL: photoURL,
          phoneNumber: phoneNumber,
          roles: ['USER'],
        });
    });
  }

  // Email verification when new user register
 
  SendVerificationMail() {
    return this.ngFireAuth.currentUser.then((user) => {
      return user.sendEmailVerification().then(() => {
        this.router.navigate(['login']);
      });
    });
  }
  // Recover password
  PasswordRecover(passwordResetEmail) {
    return this.ngFireAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(async () => {
        const toast = await this.toastCtrl.create({ message: `Password reset email has been sent, please check your inbox.`, duration: 3000, position: 'bottom' });
        await toast.present();
        this.router.navigate(['']);

      })
      .catch(async (error) => {
        window.alert(error);
        const toast = await this.toastCtrl.create({ message: error, duration: 3000, position: 'bottom' });
        await toast.present();
      });
  }
  // Returns true when user is looged in
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return user !== null && user.emailVerified !== false ? true : false;
  }
  // Returns id of logged user
  get getLoggedUserID(): string {
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    return userProfile.user_uid;
  }

  get isAdmin():boolean{
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    if(userProfile.roles.indexOf('ADMIN')>-1){
      return true;
    } 
    return false;
  }

  get isUer():boolean{
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    if(userProfile.roles.indexOf('USER')>-1){
      return true;
    } 
    return false;
  }

  get isGEST():boolean{
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    if(userProfile.roles.indexOf('GEST')>-1){
      return true;
    } 
    return false;
  }

  // Returns true when user's email is verified
   get isEmailVerified(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    if(user){
      return user.emailVerified !== false ? true : false;
    }else{
      return false;
    }
     
  }
  // Sign in with Gmail
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }
  // Auth providers
  AuthLogin(provider) {
    return this.ngFireAuth
      .signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['tabs/dashboard']); 
        });
        this.SetUserData(result.user);
      })
      .catch((error) => {
        window.alert(error);
      });
  }

  // Store user in localStorage
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${user.uid}`);
    const userData: User = {
      uid: user.user_uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(userData, {
      merge: true,
    });
  }
  // Sign-out
  SignOut() {
    return this.ngFireAuth.signOut().then(() => {
      localStorage.removeItem('user');
      localStorage.removeItem('userProfile');
      this.router.navigate(['login']);
    });
  }

  findAll() {
    return this.afStore.collection(this.collectionName).snapshotChanges();
  }

}