import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { redirectUnauthorizedTo, redirectLoggedInTo, canActivate } from '@angular/fire/auth-guard';
import { IsSignedInGuard } from './services/is-signed-in.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['tabs']);
const routes: Routes = [

  /* {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  }, */
  /* {
    path: 'login',
    loadChildren: () => import('./pages/login/login/login.module').then((m) => m.LoginPageModule),
    //...canActivate(redirectLoggedInToHome)
  }, */
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [
      IsSignedInGuard
    ]
  },
  {
    path: '',
    redirectTo: 'tabs/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/registration/registration/registration.module').then(m => m.RegistrationPageModule)
  },
  {
    path: 'verfy-email',
    loadChildren: () => import('./pages/verify-email/verfy-email/verfy-email.module').then(m => m.VerfyEmailPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/password-reset/password-reset/password-reset.module').then(m => m.PasswordResetPageModule)
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  },




];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
