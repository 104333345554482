// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig : {
    apiKey: "AIzaSyCFmUJrlXTZ7SlDCfgotytUYV-aOvuMP7k",
    authDomain: "complaint-87c79.firebaseapp.com",
    databaseURL: "https://complaint-87c79-default-rtdb.firebaseio.com",
    projectId: "complaint-87c79",
    storageBucket: "complaint-87c79.appspot.com",
    messagingSenderId: "832421262732",
    appId: "1:832421262732:web:24fd505d22e3072d5212a9",
    measurementId: "G-G8LBFMLSDV"
  },
  apApiKey:"AIzaSyAAzviFg-lWw_XbDi8acCp9opjlGAjtt88"
}



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
