import { Injectable } from "@angular/core";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { AuthenticationService } from "./authentication-service";

@Injectable({
    providedIn: 'root'
})
export class IsSignedInGuard implements CanActivate {

    constructor(private authService: AuthenticationService, private _router: Router, public ngFireAuth: AngularFireAuth,) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isSignedIn = this.authService.isLoggedIn;

        if (isSignedIn !== true) {
            this._router.navigate(['/login']);
        }
        return isSignedIn;
    }

}